<template>
  
    <v-row align="center" justify="center">
      <v-col class="text-center" cols="12">
        <h1 class="text-h4 font-weight-thin mb-4">{{ nombreAPP }}</h1>
        <h4>Bienvenido</h4>
      </v-col>
    </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'

// demos

export default {
  components: {},
  setup() {
    
  const nombreAPP = config.VUE_APP_NOMBRE
    return {
      nombreAPP
    }
  },
}
</script>
